html,
body {
    height: 100%;
    overscroll-behavior: contain;
    #root {
        min-height: 100%;
        color: #010101;
    }
}

body.overflow-hidden {
    overflow: hidden !important;
}

.MuiIconButton-root {
    &:hover {
        background-color: transparent !important;
    }
}
// .MuiTouchRipple-root {
//     display: none;
// }

.MuiMenu-list {
    padding: 0 !important;
}

.no-padding {
    padding: 0 !important;
}

.no-padding-side {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.MuiInputLabel-root {
    color: #010101 !important;
    font-weight: 500 !important;
}

.MuiFormControl-root {
    border-radius: 0.3125rem;
}

// .MuiOutlinedInput-root {
//     & > fieldset {
//       border-color: #ECF0F1 !important;
//     }

//     @media (max-width: 600px) {
//         & > .MuiInputBase-input {
//             padding: 0.75rem 0.875rem;
//         }
//     }
// }

.MuiCheckbox-root {
    &:hover {
        background-color: transparent !important;
    }
}


input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    height: 0.75rem;
    width: 0.75rem;
    margin: 13px 0;
    /* For a circular appearance we need a border-radius. */
    border-radius: 50%;

    /* The border will be the spacing between the dot and the outer circle */
    border: 2px solid #FFF;

    /* And by creating a box-shadow with no offset and no blur, we have an outer circle */
    box-shadow: 0 0 0 2px #6A6F7A;
}

input[type="radio"]:checked {
    background: #6A6F7A;
}
    
