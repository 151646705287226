.slick-slider {
    .slick-arrow {
        background: transparent;
        z-index: 9;
        color: initial;
        font-size: medium;
        line-height: normal;

        &::before {
            content: none;
        }

    }

    .slick-dots {

        & > li {
            content: none;
            margin: 0;
            margin-left: 6px;
            height: auto;

            &:first-child {
                margin-left: 0;
            }
            width: 24px;
            -webkit-transition: all 0.25s ease-in-out;
            -moz-transition: all 0.25s ease-in-out;
            -ms-transition: all 0.25s ease-in-out;
            -o-transition: all 0.25s ease-in-out;
            transition: all 0.25s ease-in-out;

            &.slick-active {
                width: 24px;
                height: 3px;
                -webkit-transition: all 0.25s ease-in-out;
                -moz-transition: all 0.25s ease-in-out;
                -ms-transition: all 0.25s ease-in-out;
                -o-transition: all 0.25s ease-in-out;
                transition: all 0.25s ease-in-out;
                & > button {
                    width: 24px;
                    height: 3px;
                    padding: 0;
                    -webkit-transition: all 0.25s ease-in-out;
                    -moz-transition: all 0.25s ease-in-out;
                    -ms-transition: all 0.25s ease-in-out;
                    -o-transition: all 0.25s ease-in-out;
                    transition: all 0.25s ease-in-out;

                    ::before {
                        content: none;
                    }
                }
            }
            & > button {
                border-radius: 2px;
                width: 24px;
                height: 3px;
                padding: 0;
                content: none;
                background-color: #fff;
                border-radius: 6.25rem;

                &::before {
                    content: none !important;
                }
            }
        }
    }

}

.slick-slider.blog-slider {
    & .slick-list { 
        
        margin: 0 ; 
    }
    
    .slick-arrow {
        height: 60px;
        width: 60px;
        
        @media (max-width: 600px) {
            display: none !important;
        }
    }

    .slick-arrow.slick-next {
        right: -51px;
    }

    .slick-arrow.slick-prev {
        left: -51px;
    }

    .slick-dots {
        bottom: -30px;

        & > li {

            &.slick-active {
                & > button {
                    background-color: #000;
                }
            }
            & > button {
                background-color: #BDBDBD;
            }
        }
    }
    
}

.product-pictures {

    .slick-dots {
        bottom: -20px;
        text-align: start;

        @media (max-width: 900px) {
            text-align: center;
        }

        & > li {

            &.slick-active {
                & > button {
                    background-color: #000;
                }
            }
            & > button {
                background-color: #BDBDBD;
            }
        }
    }

    & > .slick-dots.slick-thumb {
        position: relative;
        bottom: -15px;
        & > li {
            width: 60px;
            height: 60px;
            border-radius: 4px;
            border: 1px solid #ECF0F1;

            @media (max-width: 900px) {
                width: 45px;
                height: 45px;
            }

            &.slick-active {
                transition: none;
            }

            & > a {
                display: block;
                & > img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;

                }
            }
        }
    }
}

.slick-slider.collections-slider {
    & .slick-list {
        margin: 0;

        & .slick-slide > div {
            padding: 0 8px;
            display: flex;
            flex-direction: column;
            align-items: center;

            @media (max-width: 600px) {
                padding: 0;
            }
        }
    }

    .slick-arrow {
        height: 60px;
        width: 60px;

        @media (max-width: 600px) {
            display: none !important;
        }
    }

    .slick-arrow.slick-next {
        right: -51px;
    }

    .slick-arrow.slick-prev {
        left: -51px;
    }

    .slick-dots {
        & > li {
            &.slick-active {
                & > button {
                    background-color: #000;
                }
            }
            & > button {
                background-color: #bdbdbd;
            }
        }
    }
}
